const assetClasses = {
    item: 'i',
    controls: 'actrl',
    checkBtb: 'ac',
    settingBtn: 'as',
    title: 't',
    legend: 'l',
    attributes: 'aa',
    thumbActive: 'tha',
    thumbInactive: 'thia',
    thumbWrapper: 'tw',
};

export default assetClasses;
